import React from 'react';
import {
  required,
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  NumberInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

export const styles =
{
  inlineWidth: { width: '20em' },
  inlineFormGroup: { display: 'inline-block', marginRight: 20 }
};

const useStyles = makeStyles(styles);
const validateName = [required('Digite o nome!')];
const validateCity = [required('Selecione uma cidade!')];
const optionRendererCity = city => city ? `${city.name} - ${city.state.name}` : ``;

const CollectionPointPassportCreate = (props) =>
{
  const classes = useStyles();

  return (
  <Create title="Novo Ponto de Retirada de Passaporte" {...props}>
    <TabbedForm redirect="/collection-point-passport">
      <FormTab label="Dados">
        <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
        <TextInput source="responsibility_name" label="Responsável" fullWidth={true} />
        <TextInput source="contact_phone" label="Telefone de Contato" fullWidth={true} />
        <TextInput source="contact_email" label="E-mail de Contato" type="email" fullWidth={true} />
      </FormTab>
      <FormTab label="Endereço">
        <ReferenceInput label="Cidade" source="city_id" fullWidth={true} reference="setup/cities" validate={validateCity}>
          <AutocompleteInput optionText={optionRendererCity} optionValue="id" limitChoicesToValue={true} shouldRenderSuggestions={(val) => { return val ? val.trim().length > 2 : false }} />
        </ReferenceInput>
        <TextInput source="street" label="Rua" fullWidth={true} />
        <TextInput source="number" label="Número" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true} />
        <TextInput source="zip_code" label="CEP" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} fullWidth={true}  />
        <TextInput source="complement" label="Complemento" fullWidth={true} />
        <TextInput source="neighborhood" label="Bairro" fullWidth={true} />
        <NumberInput source="lat" label="Latitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} />
        <NumberInput source="lng" label="Longitude" className={classes.inlineWidth} formClassName={classes.inlineFormGroup} />
      </FormTab>
    </TabbedForm>
  </Create>
)};

export default CollectionPointPassportCreate;
