import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = collectionPointPassportAdmins => {
    const data = collectionPointPassportAdmins.map(collectionPointPassportAdmin => ({
        id: collectionPointPassportAdmin.id,
        ponto_retirada: collectionPointPassportAdmin.collection_point_passport_name,
        nome: collectionPointPassportAdmin.name,
        email: collectionPointPassportAdmin.email,
        master: (collectionPointPassportAdmin.is_master === true ? 'Sim' : 'Não'),
        criado_em: collectionPointPassportAdmin.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'ponto_retirada', 'nome', 'email', 'master', 'criado_em']
    });
    downloadCSV(csv, 'collectionPointPassportAdmins');
}

export default exporter;
