import * as React from "react";
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import { MenuItemLink, DashboardMenuItem, usePermissions } from 'react-admin';
import SubMenu from './submenu';

// ICONS
import ListIcon from '@material-ui/icons/List';
import CreateIcon from '@material-ui/icons/Add';
import AdminIcon from '@material-ui/icons/SupervisorAccount';
import CampaignIcon from '@material-ui/icons/RecordVoiceOver';
import CustomerIcon from '@material-ui/icons/SupervisedUserCircle';
import ServiceAdminIcon from '@material-ui/icons/VerifiedUser';
import ImageIcon from '@material-ui/icons/InsertPhoto';
import ContactIcon from '@material-ui/icons/ContactMail';
import EnterpriseContactIcon from '@material-ui/icons/Contacts';
import NewsletterIcon from '@material-ui/icons/Subscriptions';
import LicensedProductIcon from '@material-ui/icons/MonetizationOn';
import TourPackageIcon from '@material-ui/icons/FlightTakeoff';
import CopartnerIcon from '@material-ui/icons/GroupWork';
import HighlightIcon from '@material-ui/icons/Highlight';
import ThemeIcon from '@material-ui/icons/AddToQueue';
import ThemeCuriosityIcon from '@material-ui/icons/CastForEducation';
import SubThemeIcon from '@material-ui/icons/Subject';
import CityIcon from '@material-ui/icons/LocationCity';
import WayIcon from '@material-ui/icons/Subway';
import WayFilesIcon from '@material-ui/icons/FileCopy';
import WayRouteIcon from '@material-ui/icons/Directions';
import WayRouteFileIcon from '@material-ui/icons/InsertDriveFile';
import CheckPointIcon from '@material-ui/icons/PinDrop';
import CategoryTerroirsIcon from '@material-ui/icons/Assignment';
import SubCategoryTerroirsIcon from '@material-ui/icons/ClosedCaption';
import SubCategoryAttractiveIcon from '@material-ui/icons/Shop';
import AttractiveIcon from '@material-ui/icons/Museum';
import SubCategoryServiceIcon from '@material-ui/icons/LocalLaundryService';
import ServiceIcon from '@material-ui/icons/Settings';
import ServiceUpdateIcon from '@material-ui/icons/SystemUpdate';
import TermsOfUseIcon from '@material-ui/icons/Description';
import PrivacyPolicyIcon from '@material-ui/icons/VpnKey';
import ReportedProblemIcon from '@material-ui/icons/ReportProblem';
import StampPointsFileIcon from '@material-ui/icons/CardMembership';
import PopUpIcon from '@material-ui/icons/Announcement';
import EventIcon from '@material-ui/icons/DateRange';
import EventSuggestionIcon from '@material-ui/icons/Assistant';
import ContestIcon from '@material-ui/icons/EmojiEvents';
import ContestParticipantIcon from '@material-ui/icons/PersonPin';
import ContestParticipantPhotoIcon from '@material-ui/icons/AddAPhoto';
import ContestParticipantVideoIcon from '@material-ui/icons/Videocam';
import CollectionPointPassportIcon from '@material-ui/icons/AccountBox';
import CollectionPointPassportAdminIcon from '@material-ui/icons/Accessibility';

import config from '../config/config';

const Menu: FC<Props> = ({ onMenuClick, dense }) =>
{
    const [state, setState] = useState({
        menuUsersAdmin: false,
        menuCampaigns: false,
        menuUsersCustomer: false,
        menuUsersServiceAdmin: false,
        menuImages: false,
        menuContacts: false,
        menuEnterpriseContacts: false,
        menuNewsletters: false,
        menuLicensedProducts: false,
        menuTourPackages: false,
        menuCopartners: false,
        menuHighlights: false,
        menuThemes: false,
        menuThemeCuriosities: false,
        menuSubThemes: false,
        menuCities: false,
        menuWays: false,
        menuWayFiles: false,
        menuWayRoutes: false,
        menuWayRoutesFiles: false,
        menuCheckPoints: false,
        menuCategoriesTerroirs: false,
        menuSubCategoriesTerroirs: false,
        menuSubCategoriesAttractive: false,
        menuAttractives: false,
        menuSubCategoriesService: false,
        menuServices: false,
        menuServiceUpdates: false,
        menuTermsOfUse: false,
        menuPrivacyPolicy: false,
        menuReportedProblems: false,
        menuStampPointsFile: false,
        menuPopUps: false,
        menuEvents: false,
        menuEventsSuggestion: false,
        menuContests: false,
        menuContestParticipants: false,
        menuCollectionPointsPassport: false,
        menuAdminsCollectionPointPassport: false
    });

    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme);

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const { loaded, permissions } = usePermissions();

    return loaded ? (
        <div>
          <Box
            sx={
            {
              width: open ? 300 : 50,
              marginTop: 1,
              marginBottom: 1,
              transition: theme =>
              theme.transitions.create('width',
              {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
              })
            }}>
            <DashboardMenuItem
              sidebarIsOpen={open} />
            {
              (permissions.some(e => e.key === config.roles.admin_users_admin)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuUsersAdmin')}
                  isOpen={state.menuUsersAdmin}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Usuários Admin"
                  icon={<AdminIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/admin"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/admin/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_campaigns)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuCampaigns')}
                  isOpen={state.menuCampaigns}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Campanhas"
                  icon={<CampaignIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/campaign"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_users_customer)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuUsersCustomer')}
                  isOpen={state.menuUsersCustomer}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Usuários"
                  icon={<CustomerIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/customer"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_users_service_admin)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuUsersServiceAdmin')}
                  isOpen={state.menuUsersServiceAdmin}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Admin de Serviços"
                  icon={<ServiceAdminIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/service-admin"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/service-admin/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_images)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuImages')}
                  isOpen={state.menuImages}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Imagens"
                  icon={<ImageIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/image"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/image/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_contacts)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuContacts')}
                  isOpen={state.menuContacts}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Contatos"
                  icon={<ContactIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/contact"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_enterprise_contacts)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuEnterpriseContacts')}
                  isOpen={state.menuEnterpriseContacts}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Contatos Empresariais"
                  icon={<EnterpriseContactIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/enterprise-contact"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_reported_problems)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuReportedProblems')}
                  isOpen={state.menuReportedProblems}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Problemas Relatado"
                  icon={<ReportedProblemIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/check-point-reported-problem"
                  primaryText="Ponto de Check-In"
                  leftIcon={<CheckPointIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/attractive-reported-problem"
                  primaryText="Atrativo"
                  leftIcon={<AttractiveIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/service-reported-problem"
                  primaryText="Serviço"
                  leftIcon={<ServiceIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_newsletters)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuNewsletters')}
                  isOpen={state.menuNewsletters}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Newsletters"
                  icon={<NewsletterIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/newsletter"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_licensed_products)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuLicensedProducts')}
                  isOpen={state.menuLicensedProducts}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Produtos Licenciados"
                  icon={<LicensedProductIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/licensed-product"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/licensed-product/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_tour_packages)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuTourPackages')}
                  isOpen={state.menuTourPackages}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Pacotes de Turismo"
                  icon={<TourPackageIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/tour-package"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/tour-package/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_copartners)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuCopartners')}
                  isOpen={state.menuCopartners}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Parceiros"
                  icon={<CopartnerIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/copartner"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/copartner/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_highlights)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuHighlights')}
                  isOpen={state.menuHighlights}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Destaques"
                  icon={<HighlightIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/highlight"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/highlight/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_themes)) ?
                <div>
                  <SubMenu
                    handleToggle={() => handleToggle('menuThemes')}
                    isOpen={state.menuThemes}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Temas"
                    icon={<ThemeIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/theme"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/theme/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                  <SubMenu
                    handleToggle={() => handleToggle('menuThemeCuriosities')}
                    isOpen={state.menuThemeCuriosities}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Curiosidades"
                    icon={<ThemeCuriosityIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/theme-curiosity"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/theme-curiosity/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                  <SubMenu
                    handleToggle={() => handleToggle('menuSubThemes')}
                    isOpen={state.menuSubThemes}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Sub Temas"
                    icon={<SubThemeIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/sub-theme"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/sub-theme/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                </div>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_cities)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuCities')}
                  isOpen={state.menuCities}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Cidades"
                  icon={<CityIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/city"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/city/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_ways)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuWays')}
                  isOpen={state.menuWays}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Caminhos"
                  icon={<WayIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/way"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_ways)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuWayFiles')}
                  isOpen={state.menuWayFiles}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Arquivos do Caminho"
                  icon={<WayFilesIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/way-files"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/way-files/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_way_routes)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuWayRoutes')}
                  isOpen={state.menuWayRoutes}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Rotas"
                  icon={<WayRouteIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/way-route"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/way-route/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_way_routes)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuWayRoutesFiles')}
                  isOpen={state.menuWayRoutesFiles}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Arquivos da Rota"
                  icon={<WayRouteFileIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/way-route-files"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/way-route-files/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_check_points)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuCheckPoints')}
                  isOpen={state.menuCheckPoints}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Pontos de Check-In"
                  icon={<CheckPointIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/check-point"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/check-point/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_categories_terroirs)) ?
                <div>
                  <SubMenu
                    handleToggle={() => handleToggle('menuCategoriesTerroirs')}
                    isOpen={state.menuCategoriesTerroirs}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Categorias Terroirs"
                    icon={<CategoryTerroirsIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/category-terroirs"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/category-terroirs/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                  <SubMenu
                    handleToggle={() => handleToggle('menuSubCategoriesTerroirs')}
                    isOpen={state.menuSubCategoriesTerroirs}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Sub Categorias Terroirs"
                    icon={<SubCategoryTerroirsIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/sub-category-terroirs"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/sub-category-terroirs/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                </div>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_attractives)) ?
                <div>
                  <SubMenu
                    handleToggle={() => handleToggle('menuSubCategoriesAttractive')}
                    isOpen={state.menuSubCategoriesAttractive}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Sub Categorias Atrativo"
                    icon={<SubCategoryAttractiveIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/sub-category-attractive"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/sub-category-attractive/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                  <SubMenu
                    handleToggle={() => handleToggle('menuAttractives')}
                    isOpen={state.menuAttractives}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Atrativos"
                    icon={<AttractiveIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/attractive"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/attractive/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                </div>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_services)) ?
                <div>
                  <SubMenu
                    handleToggle={() => handleToggle('menuSubCategoriesService')}
                    isOpen={state.menuSubCategoriesService}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Sub Categorias Serviços"
                    icon={<SubCategoryServiceIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/sub-category-service"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/sub-category-service/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                  <SubMenu
                    handleToggle={() => handleToggle('menuServices')}
                    isOpen={state.menuServices}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Serviços"
                    icon={<ServiceIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/service"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  <MenuItemLink
                    to="/service/create"
                    primaryText="Criar"
                    leftIcon={<CreateIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                </div>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_updates_service)) ?
                <div>
                  <SubMenu
                    handleToggle={() => handleToggle('menuServiceUpdates')}
                    isOpen={state.menuServiceUpdates}
                    sidebarIsOpen={open}
                    sidebarInside={false}
                    name="Atualizações de Serviço"
                    icon={<ServiceUpdateIcon />}
                    dense={dense}
                  >
                  <MenuItemLink
                    to="/service-update"
                    primaryText="Listar"
                    leftIcon={<ListIcon />}
                    onClick={onMenuClick} />
                  </SubMenu>
                </div>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_terms_of_use)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuTermsOfUse')}
                  isOpen={state.menuTermsOfUse}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Termos de Uso"
                  icon={<TermsOfUseIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/terms-of-use"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/terms-of-use/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_privacy_policys)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuPrivacyPolicy')}
                  isOpen={state.menuPrivacyPolicy}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Política de Privacidade"
                  icon={<PrivacyPolicyIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/privacy-policy"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/privacy-policy/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_stamp_points_files)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuStampPointsFile')}
                  isOpen={state.menuStampPointsFile}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Arquivo Pontos de Carimbo"
                  icon={<StampPointsFileIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/stamp-points-file"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/stamp-points-file/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_pop_ups)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuPopUps')}
                  isOpen={state.menuPopUps}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Pop Up's"
                  icon={<PopUpIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/pop-up"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/pop-up/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_events)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuEvents')}
                  isOpen={state.menuEvents}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Agenda"
                  icon={<EventIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/event"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/event/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_events_suggestion)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuEventsSuggestion')}
                  isOpen={state.menuEventsSuggestion}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Eventos Sugeridos"
                  icon={<EventSuggestionIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/event-suggestion"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_contests)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuContests')}
                  isOpen={state.menuContests}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Concursos"
                  icon={<ContestIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/contest"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_contests)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuContestParticipants')}
                  isOpen={state.menuContestParticipants}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Participantes Concurso"
                  icon={<ContestParticipantIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/contest-participant-photo"
                  primaryText="Fotos"
                  leftIcon={<ContestParticipantPhotoIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/contest-participant-video"
                  primaryText="Videos"
                  leftIcon={<ContestParticipantVideoIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_collection_points_passport)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuCollectionPointsPassport')}
                  isOpen={state.menuCollectionPointsPassport}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Ponto de Retirada de Passaporte"
                  icon={<CollectionPointPassportIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/collection-point-passport"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/collection-point-passport/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
            {
              (permissions.some(e => e.key === config.roles.admin_admins_collection_point_passport)) ?
                <SubMenu
                  handleToggle={() => handleToggle('menuAdminsCollectionPointPassport')}
                  isOpen={state.menuAdminsCollectionPointPassport}
                  sidebarIsOpen={open}
                  sidebarInside={false}
                  name="Admin de Ponto de Retirada de Passaporte"
                  icon={<CollectionPointPassportAdminIcon />}
                  dense={dense}
                >
                <MenuItemLink
                  to="/collection-point-passport-admin"
                  primaryText="Listar"
                  leftIcon={<ListIcon />}
                  onClick={onMenuClick} />
                <MenuItemLink
                  to="/collection-point-passport-admin/create"
                  primaryText="Criar"
                  leftIcon={<CreateIcon />}
                  onClick={onMenuClick} />
                </SubMenu>
              : null
            }
          </Box>
        </div>
    ): null;
}

export default Menu;
