import React from 'react';
import {
  required,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput
} from 'react-admin';

const validateCollectionPointPassport = [required('Selecione um ponto de retirada!')];
const validateName = [required('Digite o nome!')];
const validateEmail = [required('Digite o e-mail!')];
const validatePassword = [required('Digite a senha!')];

const CollectionPointPassportAdminCreate = (props) =>
{
  return (
  <Create title="Novo Admin de Ponto de Retirada de Passaporte" {...props}>
    <SimpleForm redirect="/collection-point-passport-admin" >
      <ReferenceInput source="collection_point_passport_id" label="Ponto de Retirada" reference="setup/collection-points-passport" validate={validateCollectionPointPassport} fullWidth={true}>
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" label="Nome" fullWidth={true} validate={validateName} />
      <TextInput source="email" label="E-mail" type="email" fullWidth={true} validate={validateEmail} />
      <TextInput source="password" label="Senha" fullWidth={true} validate={validatePassword} />
      <BooleanInput source="is_master" label="Master" />
    </SimpleForm>
  </Create>
)};

export default CollectionPointPassportAdminCreate;
