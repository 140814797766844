import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = collectionPointsPassport => {
    const data = collectionPointsPassport.map(collectionPointPassport => ({
        id: collectionPointPassport.id,
        nome: collectionPointPassport.name,
        responsavel: collectionPointPassport.responsibility_name,
        telefone_contato: collectionPointPassport.contact_phone,
        email_contato: collectionPointPassport.contact_email,
        cidade: collectionPointPassport.address.city_name,
        rua: collectionPointPassport.address.street,
        number: collectionPointPassport.address.number,
        complemento: collectionPointPassport.address.complement ? collectionPointPassport.address.complement : '',
        cep: collectionPointPassport.address.zip_code,
        bairro: collectionPointPassport.address.neighborhood,
        lat: collectionPointPassport.address.lat,
        lng: collectionPointPassport.address.lng,
        criado_em: collectionPointPassport.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome', 'responsavel', 'telefone_contato', 'email_contato',
                 'cidade', 'rua', 'number', 'complemento', 'cep', 'bairro', 'lat',
                 'lng', 'criado_em']
    });
    downloadCSV(csv, 'collectionPointsPassport');
}

export default exporter;
