import { stringify } from 'query-string';
import axios from 'axios';
import config from '../config/config';
import paramsToFormData from '../utils/paramsToFormData';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  DELETE,
  HttpError
} from 'react-admin';

var jwt = require('jwt-simple');
const apiUrl = config.api.url;
const apiKey = config.api.api_key;
const jwtKey = config.jwt.key;
const localStorageAuth = config.local_storage.auth;

const provider = (type, resource, params) =>
{
  let url = '';

  const token = JSON.parse(jwt.decode(localStorage.getItem(jwt.encode(localStorageAuth, jwtKey)), jwtKey)).auth_token;
  const options =
  {
    headers:
    {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      'x-api-key': apiKey,
      'x-token': 'Bearer ' + token
    },
    data: paramsToFormData(params.data)
  };

  switch (type)
  {
    case GET_LIST:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([
          (page - 1) * perPage,
          page * perPage - 1,
        ]),
        filter: JSON.stringify(params.filter)
      };

      if (resource === 'admin')
        url = `${apiUrl}/admin/list?${stringify(query)}`;
      else if (resource === 'campaign')
        url = `${apiUrl}/campaign/list?${stringify(query)}`;
      else if (resource === 'customer')
        url = `${apiUrl}/customer/list?${stringify(query)}`;
      else if (resource === 'service-admin')
        url = `${apiUrl}/service-admin/list?${stringify(query)}`;
      else if (resource === 'image')
        url = `${apiUrl}/image/list?${stringify(query)}`;
      else if (resource === 'contact')
        url = `${apiUrl}/contact/list?${stringify(query)}`;
      else if (resource === 'enterprise-contact')
        url = `${apiUrl}/enterprise-contact/list?${stringify(query)}`;
      else if (resource === 'newsletter')
        url = `${apiUrl}/newsletter/list?${stringify(query)}`;
      else if (resource === 'licensed-product')
        url = `${apiUrl}/licensed-product/list?${stringify(query)}`;
      else if (resource === 'tour-package')
        url = `${apiUrl}/tour-package/list?${stringify(query)}`;
      else if (resource === 'copartner')
        url = `${apiUrl}/copartner/list?${stringify(query)}`;
      else if (resource === 'highlight')
        url = `${apiUrl}/highlight/list?${stringify(query)}`;
      else if (resource === 'theme')
        url = `${apiUrl}/theme/list?${stringify(query)}`;
      else if (resource === 'theme-curiosity')
        url = `${apiUrl}/theme-curiosity/list?${stringify(query)}`;
      else if (resource === 'sub-theme')
        url = `${apiUrl}/sub-theme/list?${stringify(query)}`;
      else if (resource === 'city')
        url = `${apiUrl}/city-detail/list?${stringify(query)}`;
      else if (resource === 'way')
        url = `${apiUrl}/way/list?${stringify(query)}`;
      else if (resource === 'way-files')
        url = `${apiUrl}/way/${params.filter.way_id ? params.filter.way_id : 1}/files?${stringify(query)}`;
      else if (resource === 'way-route')
        url = `${apiUrl}/way-route/list?${stringify(query)}`;
      else if (resource === 'way-route-files')
        url = `${apiUrl}/way-route/${params.filter.way_route_id ? params.filter.way_route_id : 1}/files?${stringify(query)}`;
      else if (resource === 'check-point')
        url = `${apiUrl}/check-point/list?${stringify(query)}`;
      else if (resource === 'check-point-reported-problem')
        url = `${apiUrl}/check-point/reported-problems?${stringify(query)}`;
      else if (resource === 'category-terroirs')
        url = `${apiUrl}/category-terroirs/list?${stringify(query)}`;
      else if (resource === 'sub-category-terroirs')
        url = `${apiUrl}/sub-category-terroirs/list?${stringify(query)}`;
      else if (resource === 'category-attractive')
        url = `${apiUrl}/category-attractive/list?${stringify(query)}`;
      else if (resource === 'sub-category-attractive')
        url = `${apiUrl}/sub-category-attractive/list?${stringify(query)}`;
      else if (resource === 'attractive')
        url = `${apiUrl}/attractive/list?${stringify(query)}`;
      else if (resource === 'attractive-reported-problem')
        url = `${apiUrl}/attractive/reported-problems?${stringify(query)}`;
      else if (resource === 'category-service')
        url = `${apiUrl}/category-service/list?${stringify(query)}`;
      else if (resource === 'sub-category-service')
        url = `${apiUrl}/sub-category-service/list?${stringify(query)}`;
      else if (resource === 'service')
        url = `${apiUrl}/service/list?${stringify(query)}`;
      else if (resource === 'service-update')
        url = `${apiUrl}/service-update/list?${stringify(query)}`;
      else if (resource === 'service-reported-problem')
        url = `${apiUrl}/service/reported-problems?${stringify(query)}`;
      else if (resource === 'terms-of-use')
        url = `${apiUrl}/terms-of-use/list?${stringify(query)}`;
      else if (resource === 'privacy-policy')
        url = `${apiUrl}/privacy-policy/list?${stringify(query)}`;
      else if (resource === 'setup/roles')
        url = `${apiUrl}/setup/types-role?${stringify(query)}`;
      else if (resource === 'stamp-points-file')
        url = `${apiUrl}/stamp-points-file/list?${stringify(query)}`;
      else if (resource === 'setup/states')
        url = `${apiUrl}/setup/states?${stringify(query)}`;
      else if (resource === 'pop-up')
        url = `${apiUrl}/pop-up/list?${stringify(query)}`;
      else if (resource === 'event')
        url = `${apiUrl}/event/list?${stringify(query)}`;
      else if (resource === 'event-suggestion')
        url = `${apiUrl}/event-suggestion/list?${stringify(query)}`;
      else if (resource === 'contest')
        url = `${apiUrl}/contest/list?${stringify(query)}`;
      else if (resource === 'contest-participant')
        url = `${apiUrl}/contest-participant/list?${stringify(query)}`;
      else if (resource === 'contest-participant-photo')
        url = `${apiUrl}/contest-participant-photo/list?${stringify(query)}`;
      else if (resource === 'contest-participant-video')
        url = `${apiUrl}/contest-participant-video/list?${stringify(query)}`;
      else if (resource === 'setup/state/cities')
        url = `${apiUrl}/setup/state/${params.filter.state_id ? params.filter.state_id : 1}/cities?${stringify(query)}`;
      else if (resource === 'setup/cities/with/detail')
        url = `${apiUrl}/setup/cities/with/detail?${stringify(query)}`;
      else if (resource === 'setup/cities/not/detail')
        url = `${apiUrl}/setup/state/${params.filter.state_id ? params.filter.state_id : 1}/cities/not/detail?${stringify(query)}`;
      else if (resource === 'setup/cities')
        url = `${apiUrl}/setup/cities?${stringify(query)}`;
      else if (resource === 'setup/types-file')
        url = `${apiUrl}/setup/types-file?${stringify(query)}`;
      else if (resource === 'service/not/admin')
        url = `${apiUrl}/service/list/not/admin?${stringify(query)}`;
      else if (resource === 'collection-point-passport')
        url = `${apiUrl}/collection-point-passport/list?${stringify(query)}`;
      else if (resource === 'collection-point-passport-admin')
        url = `${apiUrl}/collection-point-passport-admin/list?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_ONE:
    {
      if (resource === 'admin')
        url = `${apiUrl}/admin/byId/${params.id}`;
      else if (resource === 'campaign')
        url = `${apiUrl}/campaign/byId/${params.id}`;
      else if (resource === 'customer')
        url = `${apiUrl}/customer/byId/${params.id}`;
      else if (resource === 'service-admin')
        url = `${apiUrl}/service-admin/byId/${params.id}`;
      else if (resource === 'image')
        url = `${apiUrl}/image/byId/${params.id}`;
      else if (resource === 'licensed-product')
        url = `${apiUrl}/licensed-product/byId/${params.id}`;
      else if (resource === 'tour-package')
        url = `${apiUrl}/tour-package/byId/${params.id}`;
      else if (resource === 'copartner')
        url = `${apiUrl}/copartner/byId/${params.id}`;
      else if (resource === 'highlight')
        url = `${apiUrl}/highlight/byId/${params.id}`;
      else if (resource === 'theme')
        url = `${apiUrl}/theme/byId/${params.id}`;
      else if (resource === 'theme-curiosity')
        url = `${apiUrl}/theme-curiosity/byId/${params.id}`;
      else if (resource === 'sub-theme')
        url = `${apiUrl}/sub-theme/byId/${params.id}`;
      else if (resource === 'city')
        url = `${apiUrl}/city-detail/byId/${params.id}`;
      else if (resource === 'way')
        url = `${apiUrl}/way/byId/${params.id}`;
      else if (resource === 'way-route')
        url = `${apiUrl}/way-route/byId/${params.id}`;
      else if (resource === 'check-point')
        url = `${apiUrl}/check-point/byId/${params.id}`;
      else if (resource === 'check-point-reported-problem')
        url = `${apiUrl}/check-point/reported-problem/byId/${params.id}`;
      else if (resource === 'category-terroirs')
        url = `${apiUrl}/category-terroirs/byId/${params.id}`;
      else if (resource === 'sub-category-terroirs')
        url = `${apiUrl}/sub-category-terroirs/byId/${params.id}`;
      else if (resource === 'category-attractive')
        url = `${apiUrl}/category-attractive/byId/${params.id}`;
      else if (resource === 'sub-category-attractive')
        url = `${apiUrl}/sub-category-attractive/byId/${params.id}`;
      else if (resource === 'attractive')
        url = `${apiUrl}/attractive/byId/${params.id}`;
      else if (resource === 'attractive-reported-problem')
        url = `${apiUrl}/attractive/reported-problem/byId/${params.id}`;
      else if (resource === 'category-service')
        url = `${apiUrl}/category-service/byId/${params.id}`;
      else if (resource === 'sub-category-service')
        url = `${apiUrl}/sub-category-service/byId/${params.id}`;
      else if (resource === 'service')
        url = `${apiUrl}/service/byId/${params.id}`;
      else if (resource === 'service-update')
        url = `${apiUrl}/service-update/byId/${params.id}`;
      else if (resource === 'service-reported-problem')
        url = `${apiUrl}/service/reported-problem/byId/${params.id}`;
      else if (resource === 'terms-of-use')
        url = `${apiUrl}/terms-of-use/byId/${params.id}`;
      else if (resource === 'privacy-policy')
        url = `${apiUrl}/privacy-policy/byId/${params.id}`;
      else if (resource === 'stamp-points-file')
        url = `${apiUrl}/stamp-points-file/byId/${params.id}`;
      else if (resource === 'pop-up')
        url = `${apiUrl}/pop-up/byId/${params.id}`;
      else if (resource === 'event')
        url = `${apiUrl}/event/byId/${params.id}`;
      else if (resource === 'event-suggestion')
        url = `${apiUrl}/event-suggestion/byId/${params.id}`;
      else if (resource === 'contest')
        url = `${apiUrl}/contest/byId/${params.id}`;
      else if (resource === 'contest-participant')
        url = `${apiUrl}/contest-participant/byId/${params.id}`;
      else if (resource === 'contest-participant-photo')
        url = `${apiUrl}/contest-participant-photo/byId/${params.id}`;
      else if (resource === 'contest-participant-video')
        url = `${apiUrl}/contest-participant-video/byId/${params.id}`;
      else if (resource === 'collection-point-passport')
        url = `${apiUrl}/collection-point-passport/byId/${params.id}`;
      else if (resource === 'collection-point-passport-admin')
        url = `${apiUrl}/collection-point-passport-admin/byId/${params.id}`;
      else
        url = `${apiUrl}/${resource}/${params.id}`;

      options.url = url;

      break;
    }
    case GET_MANY:
    {
      if (resource === 'admin')
        url = `${apiUrl}/admin/list`;
      else if (resource === 'campaign')
          url = `${apiUrl}/campaign/list`;
      else if (resource === 'customer')
        url = `${apiUrl}/customer/list`;
      else if (resource === 'service-admin')
        url = `${apiUrl}/service-admin/list`;
      else if (resource === 'image')
        url = `${apiUrl}/image/list`;
      else if (resource === 'contact')
        url = `${apiUrl}/contact/list`;
      else if (resource === 'enterprise-contact')
        url = `${apiUrl}/enterprise-contact/list`;
      else if (resource === 'newsletter')
        url = `${apiUrl}/newsletter/list`;
      else if (resource === 'licensed-product')
        url = `${apiUrl}/licensed-product/list`;
      else if (resource === 'tour-package')
        url = `${apiUrl}/tour-package/list`;
      else if (resource === 'copartner')
        url = `${apiUrl}/copartner/list`;
      else if (resource === 'highlight')
        url = `${apiUrl}/highlight/list`;
      else if (resource === 'theme')
        url = `${apiUrl}/theme/list`;
      else if (resource === 'theme-curiosity')
        url = `${apiUrl}/theme-curiosity/list`;
      else if (resource === 'sub-theme')
        url = `${apiUrl}/sub-theme/list`;
      else if (resource === 'city')
        url = `${apiUrl}/city-detail/list`;
      else if (resource === 'way')
        url = `${apiUrl}/way/list`;
      else if (resource === 'way-files')
        url = `${apiUrl}/way/${params.ids[0]}/files`;
      else if (resource === 'way-route')
        url = `${apiUrl}/way-route/list`;
      else if (resource === 'way-route-files')
        url = `${apiUrl}/way-route/${params.ids[0]}/files`;
      else if (resource === 'check-point')
        url = `${apiUrl}/check-point/list`;
      else if (resource === 'check-point-reported-problem')
        url = `${apiUrl}/check-point/reported-problems`;
      else if (resource === 'category-terroirs')
        url = `${apiUrl}/category-terroirs/list`;
      else if (resource === 'sub-category-terroirs')
        url = `${apiUrl}/sub-category-terroirs/list`;
      else if (resource === 'category-attractive')
        url = `${apiUrl}/category-attractive/list`;
      else if (resource === 'sub-category-attractive')
        url = `${apiUrl}/sub-category-attractive/list`;
      else if (resource === 'attractive')
        url = `${apiUrl}/attractive/list`;
      else if (resource === 'attractive-reported-problem')
        url = `${apiUrl}/attractive/reported-problems`;
      else if (resource === 'category-service')
        url = `${apiUrl}/category-service/list`;
      else if (resource === 'sub-category-service')
        url = `${apiUrl}/sub-category-service/list`;
      else if (resource === 'service')
        url = `${apiUrl}/service/list`;
      else if (resource === 'service-update')
        url = `${apiUrl}/service-update/list`;
      else if (resource === 'service-reported-problem')
        url = `${apiUrl}/service/reported-problems?`;
      else if (resource === 'terms-of-use')
        url = `${apiUrl}/terms-of-use/list`;
      else if (resource === 'privacy-policy')
        url = `${apiUrl}/privacy-policy/list`;
      else if (resource === 'stamp-points-file')
        url = `${apiUrl}/stamp-points-files/list`;
      else if (resource === 'pop-up')
        url = `${apiUrl}/pop-up/list`;
      else if (resource === 'event')
        url = `${apiUrl}/event/list`;
      else if (resource === 'event-suggestion')
        url = `${apiUrl}/event-suggestion/list`;
      else if (resource === 'contest')
        url = `${apiUrl}/contest/list`;
      else if (resource === 'contest-participant')
        url = `${apiUrl}/contest-participant/list`;
      else if (resource === 'contest-participant-photo')
        url = `${apiUrl}/contest-participant-photo/list`;
      else if (resource === 'contest-participant-video')
        url = `${apiUrl}/contest-participant-video/list`;
      else if (resource === 'setup/roles')
        url = `${apiUrl}/setup/types-role`;
      else if (resource === 'setup/states')
        url = `${apiUrl}/setup/states`;
      else if (resource === 'setup/state/cities')
        url = `${apiUrl}/setup/state/${params.ids[0]}/cities`;
      else if (resource === 'setup/cities/with/detail')
        url = `${apiUrl}/setup/cities/with/detail`;
      else if (resource === 'setup/cities/not/detail')
        url = `${apiUrl}/setup/state/${params.ids[0]}/cities/not/detail`;
      else if (resource === 'setup/cities')
        url = `${apiUrl}/setup/cities`;
      else if (resource === 'setup/types-file')
        url = `${apiUrl}/setup/types-file`;
      else if (resource === 'service/not/admin')
        url = `${apiUrl}/service/list/not/admin`;
      else if (resource === 'collection-point-passport')
        url = `${apiUrl}/collection-point-passport/list`;
      else if (resource === 'collection-point-passport-admin')
        url = `${apiUrl}/collection-point-passport-admin/list`;
      else
        url = `${apiUrl}/${resource}`;

      options.url = url;

      break;
    }
    case GET_MANY_REFERENCE:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query =
      {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([
              (page - 1) * perPage,
              page * perPage - 1,
          ]),
          filter: JSON.stringify({
              ...params.filter,
              [params.target]: params.id,
          }),
      };

      url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case UPDATE:
    {
      if (resource === 'city')
        url = `${apiUrl}/city-detail/${params.id}`;
      else if (resource === 'service')
      {
        if (params.data.is_button_approve_reprove != null)
          url = `${apiUrl}/service/status/${params.id}`;
        else
          url = `${apiUrl}/service/edit/${params.id}`;
      }
      else if (resource === 'service-update')
        url = `${apiUrl}/service-update/edit/${params.id}`;
      else if (resource === 'service-admin')
      {
        if (params.data.is_button_approve_reprove != null)
          url = `${apiUrl}/service-admin/status/${params.id}`;
        else
          url = `${apiUrl}/service-admin/edit/${params.id}`;
      }
      else
        url = `${apiUrl}/${resource}/${params.id}`;

      options.method = 'PUT';
      options.url = url;

      break;
    }
    case CREATE:
    {
      if (resource === 'way-files')
        url = `${apiUrl}/way/${params.data.way_id}/file`;
      else if (resource === 'way-route-files')
        url = `${apiUrl}/way-route/${params.data.way_route_id}/file`;
      else if (resource === 'city')
        url = `${apiUrl}/city-detail`;
      else
        url = `${apiUrl}/${resource}`;

      options.method = 'POST';
      options.url = url;

      break;
    }
    case DELETE:
    {
      if (resource === 'way-files')
        url = `${apiUrl}/way/file/${params.id}`;
      else if (resource === 'way-route-files')
        url = `${apiUrl}/way-route/file/${params.id}`;
      else
        url = `${apiUrl}/${resource}/${params.id}`;

      options.method = 'DELETE';
      options.url = url;

      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return axios(options)
    .then(res =>
    {
      return res.data;
    })
    .then(response =>
    {
      switch (type)
      {
        case GET_LIST:
        case GET_MANY:
          const data = response.data;
          const result = response.result;
          const meta = response.meta;

          return {
            data: data ? data : result,
            total: meta.total,
            pagination:
            {
              page: meta.page,
              perPage: meta.perpage
            }
          }
        case GET_MANY_REFERENCE:
          return {
            data: response.result,
            total: response.result.lenght
          }
        case GET_ONE:
          return {
            data: response.result
          }
        case UPDATE:
        case CREATE:
          return {
            data: response.result
          }
        default:
          return { data: [] }
      }
    }
  )
  .catch(error =>
  {
    if (error.response.status < 200 || error.response.status >= 300)
    {
      if (error.response.data && error.response.data.error)
      {
        return Promise.reject(
          new HttpError(error.response.data.error, error.response.status)
        );
      }
      else
      {
        return Promise.reject(
          new HttpError(error.response.statusText, error.response.status)
        );
      }
    }
  });
};

export default provider;
