import React from 'react';
import {
    Show,
    TextField,
    BooleanField,
    SimpleShowLayout
} from 'react-admin';

const CollectionPointPassportShow = props => (
    <Show
      title="Ponto de Retirada de Passaporte" {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" label="Nome" sortable={false} />
        <TextField source="responsibility_name" label="Responsável" sortable={false} />
        <TextField source="contact_phone" label="Telefone de Contato" sortable={false} />
        <TextField source="contact_email" label="E-mail de Contato" sortable={false} />
        <TextField source="address.city_name" label="Cidade" sortable={false} />
        <TextField source="address.street" label="Rua" sortable={false} />
        <TextField source="address.number" label="Número" sortable={false} />
        <TextField source="address.complement" label="Complemento" sortable={false} />
        <TextField source="address.zip_code" label="CEP" sortable={false} />
        <TextField source="address.neighborhood" label="Bairro" sortable={false} />
        <TextField source="address.lat" label="Latitude" sortable={false} />
        <TextField source="address.lng" label="Longitude" sortable={false} />
        <BooleanField source="active" label="Ativo" sortable={false} />
      </SimpleShowLayout>
    </Show>
);

export default CollectionPointPassportShow;
