import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = events => {
    const data = events.map(event => ({
        id: event.id,
        nome_pt_br: event.name.pt_br,
        nome_en_us: event.name.en_us,
        descricao_pt_br: event.description.pt_br,
        descricao_en_us: event.description.en_us,
        mais_detalhes_pt_br: event.more_details.pt_br,
        mais_detalhes_en_us: event.more_details.en_us,
        gratis: (event.is_free === true ? 'Sim' : 'Não'),
        cidade: event.address.city_name,
        rua: event.address.street,
        number: event.address.number,
        complemento: event.address.complement ? event.address.complement : '',
        cep: event.address.zip_code,
        bairro: event.address.neighborhood,
        lat: event.address.lat,
        lng: event.address.lng,
        dias: event.days_string,
        criado_em: event.created_at_format
    }));
    const csv = convertToCSV({
        data,
        fields: ['id', 'nome_pt_br', 'nome_en_us', 'descricao_pt_br', 'descricao_en_us',
                 'mais_detalhes_pt_br', 'mais_detalhes_en_us', 'gratis', 'cidade',
                 'rua', 'number', 'complemento', 'cep', 'bairro', 'lat', 'lng',
                 'dias', 'criado_em']
    });
    downloadCSV(csv, 'events');
}

export default exporter;
