import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    EditButton
} from 'react-admin';

// own ui
import CustomFilterTextActive from '../../ui/custom-filter-text-active';
import CollectionPointPassportExporter from '../../exporter/collection-point-passport-exporter';

const CollectionPointPassportList = (props) => (
    <List
      title="Pontos de Retirada de Passaporte" {...props}
      sort={{ field: 'id', order: 'desc' }}
      filters={ <CustomFilterTextActive /> }
      filterDefaultValues={{ active: true }}
      bulkActionButtons={ false }
      exporter={ CollectionPointPassportExporter } >
        <Datagrid
          rowClick="show">
          <TextField source="id" sortable={true} />
          <TextField source="name" label="Nome" sortable={false} />
          <TextField source="responsibility_name" label="Responsável" sortable={false} />
          <TextField source="contact_phone" label="Telefone de Contato" sortable={false} />
          <TextField source="contact_email" label="E-mail de Contato" sortable={false} />
          <TextField source="address.city_name" label="Cidade" sortable={false} />
          <BooleanField source="active" label="Ativo" sortable={false} />
          <DateField source="created_at" locales="pt-BR" label="Criado em" sortable={false} />
          <EditButton />
        </Datagrid>
    </List>
);

export default CollectionPointPassportList;
